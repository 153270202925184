//==============================================================================
// Project:     www.Invest-Like-A-Pro.com
// Name:        utils/firebase
// Description: Firebase implementation.
// History:     2023, Jan 19, FUB, created
//==============================================================================

// see https://www.npmjs.com/package/firebase

// NOTE: make sure this gets imported from top layout (components/layout/page)

import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent /*, isSupported*/ } from "firebase/analytics";

//------------------------------------------------------------------------------
// * * *   F i r e b a s e   A p p   * * * * * * * * * * * * * * * * * * * * * *
//------------------------------------------------------------------------------

const firebaseConfig = {
    apiKey: "AIzaSyD1i1K83xUmo_Xm7WNu6eTGTZ7k9EOwoAM",
    authDomain: "turingtrader-org.firebaseapp.com",
    projectId: "turingtrader-org",
    storageBucket: "turingtrader-org.appspot.com",
    messagingSenderId: "1045116571905",
    appId: "1:1045116571905:web:1455d96f1707f63cfe8b00",
    measurementId: "G-G96XPF4NHV"
}

const app = initializeApp(firebaseConfig)

//------------------------------------------------------------------------------
// * * *   A n a l y t i c s   * * * * * * * * * * * * * * * * * * * * * * * * *
//------------------------------------------------------------------------------

// see https://firebase.google.com/docs/analytics/get-started?platform=web
// and https://firebase.google.com/docs/analytics/events?platform=web
// and https://developers.google.com/tag-platform/gtagjs/reference/events

/*let analytics = null
isSupported().then((fSupported) => {
    analytics = fSupported && getAnalytics(app)
})*/

const isBrowser = typeof window !== "undefined"
const analytics = isBrowser && getAnalytics(app)

//const analytics = getAnalytics(app)

export const logPageView = (pagePath) => {
    //logEvent(analytics, "page_view")

    logEvent(analytics, "page_view", {
        // see https://developers.google.com/analytics/devguides/collection/gtagjs/pages
        page_path: pagePath,
        page_location: `https://www.invest-like-a-pro.com${pagePath}`,
    })

    /*logEvent(analytics, "screen_view", { 
        // see https://firebase.google.com/docs/analytics/screenviews#web-version-9
        firebase_screen: pagePath, 
        screen_name: pagePath
    })*/
}

//==============================================================================
// end of file
