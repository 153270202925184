//==============================================================================
// Project:     www.TuringTrader.com
// Name:        utils/root-wrapper.js
// Description: Wrapper around root element.
// Created:     FUB, Apr 23, 2022
//==============================================================================

import React from "react"

import "./firebase"

//------------------------------------------------------------------------------
export const RootWrapper = ({ children }) => (<>
            {children}
</>)

//==============================================================================
// end of file
