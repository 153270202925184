exports.components = {
  "component---src-pages-404-mdx": () => import("./../../../src/pages/404.mdx" /* webpackChunkName: "component---src-pages-404-mdx" */),
  "component---src-pages-about-index-mdx": () => import("./../../../src/pages/about/index.mdx" /* webpackChunkName: "component---src-pages-about-index-mdx" */),
  "component---src-pages-blog-2018-12-feature-capital-asset-pricing-model-index-mdx": () => import("./../../../src/pages/blog/2018/12-feature-capital-asset-pricing-model/index.mdx" /* webpackChunkName: "component---src-pages-blog-2018-12-feature-capital-asset-pricing-model-index-mdx" */),
  "component---src-pages-blog-2018-12-improvement-demo-03-index-mdx": () => import("./../../../src/pages/blog/2018/12-improvement-demo-03/index.mdx" /* webpackChunkName: "component---src-pages-blog-2018-12-improvement-demo-03-index-mdx" */),
  "component---src-pages-blog-2018-12-news-hello-world-index-mdx": () => import("./../../../src/pages/blog/2018/12-news-hello-world/index.mdx" /* webpackChunkName: "component---src-pages-blog-2018-12-news-hello-world-index-mdx" */),
  "component---src-pages-blog-2018-12-showcase-various-momentum-strategies-index-mdx": () => import("./../../../src/pages/blog/2018/12-showcase-various-momentum-strategies/index.mdx" /* webpackChunkName: "component---src-pages-blog-2018-12-showcase-various-momentum-strategies-index-mdx" */),
  "component---src-pages-blog-2019-01-feature-implied-volatility-option-greeks-index-mdx": () => import("./../../../src/pages/blog/2019/01-feature-implied-volatility-option-greeks/index.mdx" /* webpackChunkName: "component---src-pages-blog-2019-01-feature-implied-volatility-option-greeks-index-mdx" */),
  "component---src-pages-blog-2019-01-feature-norgate-data-index-mdx": () => import("./../../../src/pages/blog/2019/01-feature-norgate-data/index.mdx" /* webpackChunkName: "component---src-pages-blog-2019-01-feature-norgate-data-index-mdx" */),
  "component---src-pages-blog-2019-02-feature-option-risk-graph-index-mdx": () => import("./../../../src/pages/blog/2019/02-feature-option-risk-graph/index.mdx" /* webpackChunkName: "component---src-pages-blog-2019-02-feature-option-risk-graph-index-mdx" */),
  "component---src-pages-blog-2019-02-showcase-aeromir-parking-trade-index-mdx": () => import("./../../../src/pages/blog/2019/02-showcase-aeromir-parking-trade/index.mdx" /* webpackChunkName: "component---src-pages-blog-2019-02-showcase-aeromir-parking-trade-index-mdx" */),
  "component---src-pages-blog-2019-02-showcase-keller-defensive-asset-allocation-index-mdx": () => import("./../../../src/pages/blog/2019/02-showcase-keller-defensive-asset-allocation/index.mdx" /* webpackChunkName: "component---src-pages-blog-2019-02-showcase-keller-defensive-asset-allocation-index-mdx" */),
  "component---src-pages-blog-2019-03-feature-markowitz-portfolio-optimization-index-mdx": () => import("./../../../src/pages/blog/2019/03-feature-markowitz-portfolio-optimization/index.mdx" /* webpackChunkName: "component---src-pages-blog-2019-03-feature-markowitz-portfolio-optimization-index-mdx" */),
  "component---src-pages-blog-2019-03-feature-simple-report-index-mdx": () => import("./../../../src/pages/blog/2019/03-feature-simple-report/index.mdx" /* webpackChunkName: "component---src-pages-blog-2019-03-feature-simple-report-index-mdx" */),
  "component---src-pages-blog-2019-03-improvement-indicator-speed-up-index-mdx": () => import("./../../../src/pages/blog/2019/03-improvement-indicator-speed-up/index.mdx" /* webpackChunkName: "component---src-pages-blog-2019-03-improvement-indicator-speed-up-index-mdx" */),
  "component---src-pages-blog-2019-03-showcase-keller-classical-asset-allocation-index-mdx": () => import("./../../../src/pages/blog/2019/03-showcase-keller-classical-asset-allocation/index.mdx" /* webpackChunkName: "component---src-pages-blog-2019-03-showcase-keller-classical-asset-allocation-index-mdx" */),
  "component---src-pages-blog-2019-04-feature-mfe-mae-analysis-index-mdx": () => import("./../../../src/pages/blog/2019/04-feature-mfe-mae-analysis/index.mdx" /* webpackChunkName: "component---src-pages-blog-2019-04-feature-mfe-mae-analysis-index-mdx" */),
  "component---src-pages-blog-2019-04-showcase-bensdorp-30-minute-stock-trader-index-mdx": () => import("./../../../src/pages/blog/2019/04-showcase-bensdorp-30-minute-stock-trader/index.mdx" /* webpackChunkName: "component---src-pages-blog-2019-04-showcase-bensdorp-30-minute-stock-trader-index-mdx" */),
  "component---src-pages-blog-2019-04-showcase-connors-alvarez-strategies-index-mdx": () => import("./../../../src/pages/blog/2019/04-showcase-connors-alvarez-strategies/index.mdx" /* webpackChunkName: "component---src-pages-blog-2019-04-showcase-connors-alvarez-strategies-index-mdx" */),
  "component---src-pages-blog-2019-05-feature-implicit-data-source-descriptors-tiingo-index-mdx": () => import("./../../../src/pages/blog/2019/05-feature-implicit-data-source-descriptors-tiingo/index.mdx" /* webpackChunkName: "component---src-pages-blog-2019-05-feature-implicit-data-source-descriptors-tiingo-index-mdx" */),
  "component---src-pages-blog-2019-06-feature-algorithms-as-data-sources-index-mdx": () => import("./../../../src/pages/blog/2019/06-feature-algorithms-as-data-sources/index.mdx" /* webpackChunkName: "component---src-pages-blog-2019-06-feature-algorithms-as-data-sources-index-mdx" */),
  "component---src-pages-blog-2019-06-feature-fred-data-feed-index-mdx": () => import("./../../../src/pages/blog/2019/06-feature-fred-data-feed/index.mdx" /* webpackChunkName: "component---src-pages-blog-2019-06-feature-fred-data-feed-index-mdx" */),
  "component---src-pages-blog-2019-07-feature-native-reports-c-sharp-templates-index-mdx": () => import("./../../../src/pages/blog/2019/07-feature-native-reports-c-sharp-templates/index.mdx" /* webpackChunkName: "component---src-pages-blog-2019-07-feature-native-reports-c-sharp-templates-index-mdx" */),
  "component---src-pages-blog-2019-07-feature-run-from-source-index-mdx": () => import("./../../../src/pages/blog/2019/07-feature-run-from-source/index.mdx" /* webpackChunkName: "component---src-pages-blog-2019-07-feature-run-from-source-index-mdx" */),
  "component---src-pages-blog-2019-07-update-better-documentation-index-mdx": () => import("./../../../src/pages/blog/2019/07-update-better-documentation/index.mdx" /* webpackChunkName: "component---src-pages-blog-2019-07-update-better-documentation-index-mdx" */),
  "component---src-pages-blog-2019-07-update-turingtrader-binary-index-mdx": () => import("./../../../src/pages/blog/2019/07-update-turingtrader-binary/index.mdx" /* webpackChunkName: "component---src-pages-blog-2019-07-update-turingtrader-binary-index-mdx" */),
  "component---src-pages-blog-2019-07-update-turingtrader-goes-beta-index-mdx": () => import("./../../../src/pages/blog/2019/07-update-turingtrader-goes-beta/index.mdx" /* webpackChunkName: "component---src-pages-blog-2019-07-update-turingtrader-goes-beta-index-mdx" */),
  "component---src-pages-blog-2019-08-improvement-new-report-template-index-mdx": () => import("./../../../src/pages/blog/2019/08-improvement-new-report-template/index.mdx" /* webpackChunkName: "component---src-pages-blog-2019-08-improvement-new-report-template-index-mdx" */),
  "component---src-pages-blog-2019-09-feature-splicing-data-source-index-mdx": () => import("./../../../src/pages/blog/2019/09-feature-splicing-data-source/index.mdx" /* webpackChunkName: "component---src-pages-blog-2019-09-feature-splicing-data-source-index-mdx" */),
  "component---src-pages-blog-2019-10-feature-bias-free-universes-index-mdx": () => import("./../../../src/pages/blog/2019/10-feature-bias-free-universes/index.mdx" /* webpackChunkName: "component---src-pages-blog-2019-10-feature-bias-free-universes-index-mdx" */),
  "component---src-pages-blog-2019-11-news-turingtrader-com-index-mdx": () => import("./../../../src/pages/blog/2019/11-news-turingtrader-com/index.mdx" /* webpackChunkName: "component---src-pages-blog-2019-11-news-turingtrader-com-index-mdx" */),
  "component---src-pages-blog-2020-04-showcase-connors-alpha-formula-index-mdx": () => import("./../../../src/pages/blog/2020/04-showcase-connors-alpha-formula/index.mdx" /* webpackChunkName: "component---src-pages-blog-2020-04-showcase-connors-alpha-formula-index-mdx" */),
  "component---src-pages-blog-2020-05-showcase-keller-lethargic-asset-allocation-index-mdx": () => import("./../../../src/pages/blog/2020/05-showcase-keller-lethargic-asset-allocation/index.mdx" /* webpackChunkName: "component---src-pages-blog-2020-05-showcase-keller-lethargic-asset-allocation-index-mdx" */),
  "component---src-pages-blog-2020-07-child-algorithms-index-mdx": () => import("./../../../src/pages/blog/2020/07-child-algorithms/index.mdx" /* webpackChunkName: "component---src-pages-blog-2020-07-child-algorithms-index-mdx" */),
  "component---src-pages-blog-2020-07-simulator-hooks-index-mdx": () => import("./../../../src/pages/blog/2020/07-simulator-hooks/index.mdx" /* webpackChunkName: "component---src-pages-blog-2020-07-simulator-hooks-index-mdx" */),
  "component---src-pages-blog-2021-03-honest-review-index-mdx": () => import("./../../../src/pages/blog/2021/03-honest-review/index.mdx" /* webpackChunkName: "component---src-pages-blog-2021-03-honest-review-index-mdx" */),
  "component---src-pages-blog-2022-11-keller-baa-index-mdx": () => import("./../../../src/pages/blog/2022/11-keller-baa/index.mdx" /* webpackChunkName: "component---src-pages-blog-2022-11-keller-baa-index-mdx" */),
  "component---src-pages-blog-2022-12-v-2-algos-index-mdx": () => import("./../../../src/pages/blog/2022/12-v2-algos/index.mdx" /* webpackChunkName: "component---src-pages-blog-2022-12-v-2-algos-index-mdx" */),
  "component---src-pages-blog-2023-03-home-upgrade-index-mdx": () => import("./../../../src/pages/blog/2023/03-home-upgrade/index.mdx" /* webpackChunkName: "component---src-pages-blog-2023-03-home-upgrade-index-mdx" */),
  "component---src-pages-blog-2023-03-keller-haa-index-mdx": () => import("./../../../src/pages/blog/2023/03-keller-haa/index.mdx" /* webpackChunkName: "component---src-pages-blog-2023-03-keller-haa-index-mdx" */),
  "component---src-pages-blog-2023-03-sixteen-index-mdx": () => import("./../../../src/pages/blog/2023/03-sixteen/index.mdx" /* webpackChunkName: "component---src-pages-blog-2023-03-sixteen-index-mdx" */),
  "component---src-pages-blog-index-mdx": () => import("./../../../src/pages/blog/index.mdx" /* webpackChunkName: "component---src-pages-blog-index-mdx" */),
  "component---src-pages-colors-mdx": () => import("./../../../src/pages/colors.mdx" /* webpackChunkName: "component---src-pages-colors-mdx" */),
  "component---src-pages-download-index-mdx": () => import("./../../../src/pages/download/index.mdx" /* webpackChunkName: "component---src-pages-download-index-mdx" */),
  "component---src-pages-index-mdx": () => import("./../../../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */)
}

